import React from "react";
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';
import { RiGitlabFill } from 'react-icons/ri';
import { images } from "../constants";

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href="https://gitlab.com/dylthac">
                <RiGitlabFill />
                </a>
            </div>
            <div>
                <a href="https://www.linkedin.com/in/dylanthacker/">
                <FaLinkedinIn />
                </a>
            </div>
            <div>
                <a href={images.dtresume}>
                <BsFileEarmarkPerson />
                </a>
            </div>
        </div>
    )
}

export default SocialMedia